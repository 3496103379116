<template>
  <div class="layout-padding">
  <div class="slotmanage">
          <div class="title">
              <h3>槽位管理</h3>
               <hr style="color: rgba(153,153,153,0.2);"></hr>
          </div>
          <div style="display: flex;justify-content: space-between; margin: 16px 0; padding: 0 24px;">
             <el-input style="width: 474px;" v-model="form.name" placeholder="请输入槽位名称/标识搜索">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
             </el-input>
             <div>
                  <el-button type="success" icon="el-icon-search" @click="querySlotList">搜索</el-button>
                  <el-button type="warning" icon="el-icon-refresh" @click="slotReset">重置</el-button>
             </div>
          </div>
      </div>
      <div class="slotCon">
          <div class="left">
              <h3>槽位分类</h3>
              <el-tree
                  style="margin: 30px 0;"
                  :data="treedata"
                  node-key="tenantId"
                  default-expand-all
                  highlight-current
                  @node-click="handleNodeClick"
                  :expand-on-click-node="false">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span :title="node.label" class="custom-tree-node-label">{{ node.label }}</span>
                      <span>
                          <el-button
                          type="text"
                          size="mini"
                          icon="el-icon-more"
                          @click="append($event,data)">
                          </el-button>
                      </span>
                  </span>
              </el-tree>
              <div class="slottypebutton" @click="addSlotType('class')">
                  <i class="el-icon-plus"></i>
                   新增槽位分类
              </div>
          </div>
          <div class="right">
              <div class="righttop">
                <el-button type="primary" icon="el-icon-plus" @click="addSlot">新增槽位</el-button>
                <div>
                  <el-button icon="el-icon-refresh" circle @click="slotReset"></el-button>
                  <el-button icon="el-icon-s-unfold" circle></el-button>
                  <el-button icon="el-icon-search" circle @click="querySlotList"></el-button>
                </div>
              </div>
              <div style="margin-top: 20px;">
                <el-table
                 border :data="tableData" style="width: 100%;height: 500px;overflow-y: auto;"  :header-cell-style="{ background: '#f7f8fa', color: '#323233', fontWeight: 500 }">
                  <el-table-column type="index" width="50" label="序号" :index="getIndex">
                  </el-table-column>
                  <el-table-column prop="name" label="槽位名称">
                  </el-table-column>
                  <el-table-column prop="sign" label="槽位标识">
                  </el-table-column> 
                  <el-table-column prop="slotCategoryName" label="槽位分类">
                  </el-table-column>
                  <el-table-column prop="pagesName" label="跳转页面">
                    <template #default="scope">
                      <div style="display: flex;">
                        <el-tag v-if="scope.row.pagesName">{{scope.row.pagesName}}</el-tag>
                        <div v-else class="f14 jump-button" @click="open(scope.row)">选择跳转到的页面</div>
                        <div v-if="scope.row.pagesName" style="width: 70px;margin-left: 10px;" class="f14 jump-button" @click="open(scope.row,'edit')">修改</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column  label="操作">
                    <template #default="scope">
                      <el-button  type="text" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  style="float: right;margin-top: 15px;"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :page-sizes="[10, 20, 50, 100, 200]"
                  :page-size="form.size"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total">
                </el-pagination>
              </div>
          </div>
      </div>
      <el-dialog :visible.sync="addSlotListDialog"  width="600px" :close-on-click-modal="false">
        <div slot="title" class="header-title addTypeTitle"><span></span>新增槽位</div>
        <el-form
            label-position="right"
            :model="slotform"
            ref="slotform"
            size="small"
            :rules="addslotrules"
            label-width="100px"
        >
            <el-form-item label="槽位名称：" prop="name">
                <el-input v-model="slotform.name" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="槽位标识：" prop="sign">
              <el-input v-model="slotform.sign" maxlength="20"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="slotListDialogClose">取 消</el-button>
          <el-button type="primary" :loading="addslotloading" @click="slotSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog :visible.sync="addSlotDialog"  width="600px">
          <div slot="title" class="header-title addTypeTitle"><span></span>{{addTypeTitle}}</div>
          <el-form
              label-position="right"
              :model="typeform"
              ref="typeform"
              size="small"
              :rules="addtyperules"
              label-width="100px"
          >
              <el-form-item label="分类名称：" prop="name">
                  <el-input v-model="typeform.name" maxlength="20"></el-input>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="slotDialogClose">取 消</el-button>
            <el-button type="primary" :loading="slottypeloading" @click="slotTypeSubmit">确 定</el-button>
          </span>
      </el-dialog>
      <el-dialog class="notheader" :style="{top:clientY - 180 + 'px'}" :visible.sync="leveltypeshow" width="180px" :show-close="false" :modal="false">
        <div class="leveltype" v-if="leveltypeshow">
          <p @click="addSlotType('level')" :class="{clickactive:clickactive  =='level'}"><i class="el-icon-circle-plus-outline mr10"></i>新增子分类</p>
          <p @click="addSlotType('edit',)" :class="{clickactive:clickactive == 'edit'}" style="margin: 20px 0;"><i class="el-icon-edit mr10"></i>修改分类名称</p>
          <p @click="deleteSlotType" :class="{clickactive:clickactive=='delete'}"><i class="el-icon-delete mr10"></i>删除分类</p>
        </div>
      </el-dialog>
      <JumpDialogView ref="jump" @pageConfirm="pageConfirm"></JumpDialogView>
  </div>
</template>
<script>
import JumpDialogView from "@/pages/mall/slotmanage/JumpDialog"
import { getSlotList,getDeleteSlot,getSlotCategoryList,getAddSlotType,getAddSlot,getDeleteSlotType,getEditSlotType,getEditSlotPage} from "@/api/slot";
export default {
  name:'index',
  data() {
    return {
      mValue: undefined,
      form:{
        name:'',
        sign:"",
        slotCategoryId:'',
        size: 10,
        current: 1,
      },
      typeform:{
        name:'',
      },
      addtyperules:{
          name: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 0, max: 20, message: '长度在 20 个字符', trigger: 'blur' }
        ],
      },
      addslotrules:{
        name: [
          { required: true, message: '请输入槽位名称', trigger: 'blur' },
          { min: 0, max: 20, message: '长度在 20 个字符', trigger: 'blur' }
        ],
        sign: [
          { required: true, message: '请输入槽位标识', trigger: 'blur' },
          { min: 0, max: 20, message: '长度在 20 个字符', trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              if (/[a-zA-z]$/.test(value) == false) {
                callback(new Error("请输入英文"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          }
        ],
      },
      slotform:{
        name:'',
        sign:'',
      },
      addTypeTitle:'',
      addSlotDialog:false,//新增槽位分类弹窗
      leveltypeshow:false,//子级分类是否显示
      addSlotListDialog:false,//新增槽位弹窗
      tableData:[],
      total:0,
      treedata:[],
      mValue:undefined,
      slottypeloading:false,
      addslotloading:false,
      parentId:"",
      clicknode:{},
      linkdialogVisible:false,
      clickactive:null,
      clientY:349
    }
  },
  components:{JumpDialogView},
  watch: {
      leveltypeshow: {
          deep:true,
          handler(val){
            if(!val){
              this.parentId = ""
              this.clicknode = {}
              this.clickactive = null
            }
          },
      },
      value: {
          immediate: true,
          deep:true,
          handler(newVal,oldVal) {
              this.mValue = this.value;
          },
      },
      mValue: {
          handler() {
              this.$emit("input", this.mValue);
              this.$emit("change", this.mValue);
          },
      },
     
  },
  mounted() {
    this.getSlotData()
    this.getsSlotCategoryData()
  },
  methods: {
   
    //跳转链接确认
    pageConfirm(data){
      console.log('pageConfirm------',data)
      let params = {};
      params.pagesId = data.pagesId
      params.sign = data.csign
      params.aid = this.$store.state.project.aid
      getEditSlotPage(params).then(res =>{
        
          if(res.data){
            this.$message({
              type: "success",
              message: "跳转页面保存成功",
            });
            this.getSlotData()
            this.$refs.jump.dialogVisible = false
          }else{
            this.$message({
              type: "warning",
              message: "跳转页面保存失败",
            });
          }
          this.$refs.jump.dialogVisible = false
      })
    },
    //当树节点被点击时
    handleNodeClick(data){
      this.form.slotCategoryId = data.slotCategoryId
      this.getSlotData()
    },
    handleSizeChange(val){
      this.form.size = val
      this.getSlotData()
    },
    handleCurrentChange(val){
      this.form.current = val
      this.getSlotData()
    },
    async getSlotData(){
      let aid = this.$store.state.project.aid?this.$store.state.project.aid:this.$route.params.aid
      this.form.aid = aid
      let { data } = await getSlotList(this.form);
      if(data){
        this.tableData = data.records
        this.form.current = data.current
        this.form.size = data.size
        this.total = data.total
      }
      
    },
    async getsSlotCategoryData(){
      let aid = this.$store.state.project.aid?this.$store.state.project.aid:this.$route.params.aid
      let params = {aid:aid}
      let { data } = await getSlotCategoryList(params);
      if(data){
        this.treedata = this.handletreedata(data)
      }
    },
    handletreedata(data){
      let result = data.map(item =>({
        ...item,
        label:item.name,
        id:item.slotCategoryId,
        children:item.children?this.handletreedata(item.children):[]
      }))
      return result
    },
    //槽位列表搜索
    querySlotList(){
      this.getSlotData()
    },
    //重置
    slotReset(){
      this.form.name = ''
      this.getSlotData()
    },
    open(val,type) {
      this.$refs.jump.open(val,type)
    },
    //新增槽位
    addSlot(){
      this.addSlotListDialog = true
    },
    getIndex(index,pagination){
      return (this.form.current-1) * this.form.size + index + 1
    },
    append(event,data) {
      console.log('append----event',event)
      console.log('append----dataa',data)
      this.leveltypeshow = true
      this.parentId = data.parentId
      this.clicknode = data
      this.clientY = event.clientY
    },

    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
    },
    addSlotType(val){
      this.clickactive = val
      if(val == 'class'){
        this.addTypeTitle = '新增槽位分类'
      }else if(val == 'level'){
        this.addTypeTitle = '新增子分类'
      }else{
        this.addTypeTitle = '修改分类名称',
        this.typeform.name = this.clicknode.name
      }
      this.addSlotDialog = true
    },
    //删除分类
    deleteSlotType(){
      this.clickactive = 'delete'
      let data = this.clicknode.slotCategoryId
      this.$confirm("确认要进行删除操作吗?", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        getDeleteSlotType([data]).then(res =>{
          if(res.data){
            this.$message({type:"success",message: "删除分类成功",})
            this.getsSlotCategoryData()
          }
          if(res.code == 1){
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
          this.parentId = ""
          this.leveltypeshow = false
        })
      }).catch(()=>{
        this.parentId = ""
        this.leveltypeshow = false
      })
    },
    //新增分类提交
    slotTypeSubmit(){
      let API = null;
      let msg = null;
      let parent = ""
      if(this.addTypeTitle == "新增槽位分类" || this.addTypeTitle == "新增子分类"){
        API = getAddSlotType
        msg = "新增分类成功"
      }else if(this.addTypeTitle == "修改分类名称"){
        API = getEditSlotType
        msg = "编辑分类成功"
      }
      if(this.addTypeTitle == "新增槽位分类"){
        parent = ""
      }else if(this.addTypeTitle == "新增子分类"){
        parent = this.clicknode.slotCategoryId
      }else if(this.addTypeTitle == "修改分类名称" ){
        if(this.clicknode.parentId){
          parent = this.clicknode.parentId
        }else{
          parent = null
        }
      }
      this.$refs["typeform"].validate((valid) => {
        if (valid) {
          this.slottypeloading = true
          let data = JSON.parse(JSON.stringify(this.typeform))
          data.parentId = parent
          data.aid = this.$store.state.project.aid
          data.slotCategoryId = this.addTypeTitle == "新增子分类" ? "" : this.clicknode.slotCategoryId
          API(data).then(res =>{
            if(res.data){
              this.$message({
                type: "success",
                message: msg,
              });
              this.getsSlotCategoryData()
            }else{
              this.$message({
                type: "warning",
                message: res.msg,
              });
            }
          })
          this.parentId = ""
          this.addSlotDialog = false
          this.leveltypeshow = false
          
          this.slotDialogClose()
        } else {
          return false;
        }
      });
     
    },
    //新增槽位分类取消
    slotDialogClose(){
      this.addSlotDialog = false
      this.typeform.name = ''
      this.slottypeloading = false
      this.parentId = ""
      this.leveltypeshow = false,
      this.clicknode = {}
    },
    handleDelete(row){
      this.$confirm("确认要进行删除操作吗?", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        getDeleteSlot([row.slotId]).then(res =>{
          if(res.data){
            this.$message({type:"success",message: "删除成功",})
            this.getSlotData()
          }
          if(res.code == 1){
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        })
      }).catch(()=>{
      })
    },
    slotListDialogClose(){
      this.slotform = {}
      this.addslotloading = false
      this.addSlotListDialog = false
    },
    slotSubmit(){
      if(!this.form.slotCategoryId){
        this.$message({type:"warning",message: "请先选择槽位分类",})
        return false
      }
      this.$refs["slotform"].validate((valid) => {
        if (valid) {
          this.addslotloading = true
          let params = this.slotform
          params.slotCategoryId = this.form.slotCategoryId
          params.aid = this.$store.state.project.aid
          getAddSlot(params).then(res =>{
            if(res.data){
              this.$message({
                type: "success",
                message: "新增槽位成功",
              });
            }
            this.addslotloading = false
            this.addSlotListDialog = false
            this.getSlotData()
          })
        } else {
          this.addslotloading = false
          return false
        }
        this.slotListDialogClose()
      });
    }
  }
  
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar{
  display: none;
}
::v-deep .selectAllbtnDis .cell .el-checkbox__inner {
  display: none;
}
::v-deep.notheader{
  left:-52%;
  .el-dialog__header {
    display: none!important;
  }
  
}
.clickactive{
  color: #409eff;
}
.jump-button {
border-radius: 3px;
background: #409eff;
color: white;
padding: 4px 7px;
text-align: center;
}
.leveltype{
background: #fff;
.mr10{
  margin-right: 10px;
}
p{
  cursor: pointer;
}
}
.slotmanage{
  margin: 24px;
  height: 100%;
  overflow-y: auto;
  background: #fff;
  h3{
      margin: 16px 0;
      padding: 0 24px;
      font-weight: bold;
  }
}

.slotCon{
  margin: 24px;
  display: flex;
  height: 72vh;
  overflow-y: auto;
  background: #fff;
  .left{
      padding: 30px 24px;
      width: 240px;
      height:70vh;
      overflow: auto;
      border-right: 1px solid rgba(153,153,153,0.2);
      h3{
          font-weight: bold;
      }
      ::v-deep .el-tree-node__content{
          height: 36px!important;
      }
      .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
  
    background-color: rgba(135, 206, 235, 0.2);
    color:#409eff; 
    font-weight: bold;
  }    
      .custom-tree-node {
          margin: 10px 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow-x: auto;
          font-size: 14px;
          padding-right: 8px;
          .custom-tree-node-label{
            width: 88%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .el-button--text{
              color: #999999;
          }
      }
      .slottypebutton{
          margin: 0 auto;
          width: 200px;
          height: 40px;
          font-size: 14px;
          line-height: 40px;
          text-align: center;
          color: #409EFF;
          border: 1px solid #409EFF;
          cursor: pointer;
      }
  }
  .right{
      flex:1;
      padding: 24px 37px;
      .righttop{
        display: flex;
        justify-content: space-between;
      }
  }
}
.addTypeTitle{
display: flex;
font-size: 18px;
font-weight: 600;
span{
  margin-right: 8px;
  width: 5px;
  background: #409eff;
  border-radius: 10px;
}
}
</style>
