<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-08-12 17:23:08
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-08-31 20:12:08
-->
<template>
  <el-dialog :visible.sync="dialogVisible" title="请选择跳转页面" width="37%" @close="dialogClose">
    <el-table
      ref="multipleTable"
      row-key="pagesId"
      :header-cell-class-name="cellClass"
      :data="dataList"
      v-loading="dataListLoading"
      class="insidTab"
      size="mini"
      stripe
      max-height="500"
      @selection-change="handleSelectionChange">
      <el-table-column align="center" reserve-selection type="selection" width="55"> </el-table-column>
      <el-table-column label="页面名称" prop="name"></el-table-column>
      <el-table-column label="槽位" prop="sign"> </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryForm.current"
      :page-size="queryForm.size"
      :pager-count="5"
      :page-sizes="[10, 20, 50, 100, 200]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalPage"
      background
      class="pagina mt15">
    </el-pagination>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogClose">取 消</el-button>
      <el-button type="primary" @click="pagesSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import { getFormPage} from "@/api/project"

export default {
  name: "JumpDialog",

  data() {
    return {
      dialogVisible: false,
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      queryForm: {
        aid: '',
        size: 10,
        current: 1,
      },
      selectedRow: [],
      sign:'',
      row:{}
    };
  },
  computed: {
    ...mapGetters(["project"]),
  },
  methods: {
    cellClass(){
      return 'selectAllbtnDis'
    },
    dialogClose(){
      this.dialogVisible = false
      this.selectedRow = []
      this.row = {}
      this.$refs.multipleTable.clearSelection();
    },
    handleSizeChange(val) {
      this.queryForm.size = val;
      this.queryForm.current = 1;
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.queryForm.current = val;
      this.getDataList();
    },
    handleSelectionChange(selectedRows) {
      this.$nextTick(() =>{
        if (selectedRows.length > 1) {
          this.$refs.multipleTable.clearSelection();
          this.$refs.multipleTable.toggleRowSelection(selectedRows.pop());
        }
        this.selectedRow = selectedRows[0];
      })

    },
    // 开启
    open(val,type) {
      this.dialogVisible = true;
      this.getDataList()
      this.sign = val.sign
      if(type){
        this.row = val
      }

    },
       // 获取table 列表
    getDataList() {
      this.queryForm.aid = this.project.aid;
      getFormPage(this.queryForm).then(res => {
        if(res.data){
          this.dataList = res.data.records;
          this.totalPage = res.data.total;
          let row = null
          if(this.row){
            row =  this.dataList.filter(item =>item.pagesId == this.row.pagesId)[0]
          }
          if(row){
            this.$refs.multipleTable.toggleRowSelection(row, true)
            this.selectedRow = row
          }
        }
      }).catch((err) => {
        this.$message.error(err.msg);
      });
    },
    pagesSubmit(){
      this.selectedRow.csign = this.sign
      this.$emit("pageConfirm",this.selectedRow)
      this.$refs.multipleTable.clearSelection();
      this.selectedRow = []
      this.row = {}
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .selectAllbtnDis .cell .el-checkbox__inner {
  display: none;
}
</style>
